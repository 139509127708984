.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    font-family: Lato;
}

.brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.navigation-menu {
    margin-left: auto;
}

.navigation-menu ul {
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
}

.navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
}

.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
}

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: rgb(99 102 241);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: #2642af;
}


@media screen and (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .navigation-menu ul {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        /* height: calc(100vh - 77px); */
        background-color: white;
        border-top: 1px solid black;
        z-index: 1;
    }

    .navigation-menu li {
        text-align: center;
        margin: 0;
      }

    .navigation-menu li a {
        color: black;
        width: 100%;
        padding: 1rem 0;
    }

    .navigation-menu li a:hover {
        color: #283b8b;
    }

    .navigation-menu li:hover {
        background-color: #eee;
    }
    .navigation-menu.expanded ul {
        display: block;
    }
}

