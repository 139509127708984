@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta'), url("./fonts/Recoleta-RegularDEMO.otf")
  format('opentype');
}

@font-face {
  font-family: "Grotesk";
  src: local("Grotesk"), url("./fonts/Grotesk/VastagoGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Lato';
  src: local("Lato"), url("./fonts/Lato/Lato-Regular.ttf") format("truetype");
}

body {
  font-family: Recoleta;
}
/* * {
  border: 1px solid red;
} */
.font-title {
  font-family: Grotesk;
}
.font-paragraph {
  font-family: Lato;
}


