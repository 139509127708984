body {
  font-family: Recoleta;
}
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .animate-rotate {
    animation: rotate 1s linear infinite;
  }